<template>
  <div>
    <van-image
      v-for="(src, index) in arrImgUrl"
      :key="index"
      width="100%"
      lazy-load
      :src="src"
    />
  </div>
</template>
<script>
// import requestHZAPI from '@/axios/HaoZhaoTax'
export default {
  name: 'server',
  data () {
    return {
      arrImgUrl: [
        'https://ossfile.wealthwiser.cn/material/contract/template/model-0.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-1.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-2.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-3.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-4.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-5.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-6.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-7.png',
        'https://ossfile.wealthwiser.cn/material/contract/template/model-8.png'
      ]
    }
  },
  created () {
    this.getContract()
  },
  methods: {
    getContract () {
      const formData = new FormData()
      formData.append('fileType', '1')
      // requestHZAPI.getUserContract(formData).then(res => {
      //   console.log(res, '=====================')
      // })
    }
  }
}
</script>
